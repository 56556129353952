import React, { useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Accordion, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import EnvironmentHeaderIcon from "../../assets/images/indicator-environment-icon.svg";
import SocialHeaderIcon from "../../assets/images/indicator-social-icon.svg";
import ConflictIcon from "../../assets/images/indicator-conflict-icon.svg";
import BaseProductCategoryAccordion from "./BaseProductCategoryAccordion";
import AccordionProductListItem from "./AccordionProductListItem";
import LoadingSpinner from "../LoadingSpinner";
import {
  productsRequest,
  overlaysRequest,
  productsToggle,
  timeseriesUpdate,
  timeseriesFeatureUpdate,
  resetActiveIndicators,
} from "actions";
import parseTimestamp from "../../lib/parseTimestamp";
import { MAX_SELECTION_COUNT } from "../../lib/constants";
import AccordionConflictItem from "./AccordionConflictItem";

export const indicatorIcons = {
  Environment: EnvironmentHeaderIcon,
  Social: SocialHeaderIcon,
  Conflict: ConflictIcon,
};

const CONFLICT_CATEGORY = "Conflict";

const getCategoryCount = (products, activeProducts) =>
  products.filter((product) => activeProducts.includes(product.name)).length;

const getCategoryLabelByCategory = (products) =>
  products.reduce((categoriesMap, p) => {
    if (!(p.category in categoriesMap)) {
      categoriesMap[p.category] = p.category_label;
    }
    return categoriesMap;
  }, {});

const organizeProductsForAccordion = (products) =>
  products
    .filter((p) => p.sidebar)
    .reduce((listByCategory, product) => {
      const categoryProducts =
        product.category in listByCategory
          ? [...listByCategory[product.category], product]
          : [product];
      listByCategory[product.category] = categoryProducts;
      return listByCategory;
    }, {});

const IndicatorsAccordionGroup = () => {
  const { products, products_loading, products_active, date } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const { language } = useI18next();
  const { t } = useTranslation();
  const atMaxIndicatorSelection = products_active.length >= MAX_SELECTION_COUNT;

  useEffect(() => {
    dispatch(productsRequest(language));
    dispatch(overlaysRequest(language));
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(timeseriesUpdate());
    dispatch(timeseriesFeatureUpdate());
  }, [dispatch, products, products_active, date]);

  const groupedProducts = useMemo(
    () => organizeProductsForAccordion(products),
    [products]
  );

  const categoryLabelByCategory = useMemo(
    () => getCategoryLabelByCategory(products),
    [products]
  );

  const onProductClick = (e) => {
    dispatch(productsToggle(e.currentTarget.id));
  };

  const handleHideAll = () => {
    dispatch(resetActiveIndicators());
  };

  const isProductDisabled = useCallback(
    (product) =>
      date < parseTimestamp(product.date_min) ||
      date > parseTimestamp(product.date_max),
    [date]
  );

  const willExceedMaxSelection = useCallback(
    // Disable new selection if already at max indicator selections
    (product) =>
      !products_active?.includes(product.name) && atMaxIndicatorSelection,
    [products_active, atMaxIndicatorSelection]
  );

  return (
    <div className="sidebar-indicators">
      <div className="sidebar-indicators-upper-label">
        <span className="label">{t("Indicators")}</span>
        {!products_loading && (
          <Button className="hide-all-control" onClick={handleHideAll}>
            <FontAwesomeIcon icon={faEyeSlash} />
            {t("Hide all")}
          </Button>
        )}
      </div>
      {products_loading ? (
        <div className="loading-spinner-container">
          <LoadingSpinner style={{ color: "#007CC2", alignSelf: "center" }} />
        </div>
      ) : (
        <Accordion defaultActiveKey="1">
          {Object.entries(groupedProducts).map(
            ([category, products], index) =>
              category !== CONFLICT_CATEGORY && (
                <BaseProductCategoryAccordion
                  categoryIcon={indicatorIcons[category]}
                  categoryLabel={categoryLabelByCategory[category]}
                  selectionsCount={getCategoryCount(
                    groupedProducts[category],
                    products_active
                  )}
                  eventKey={index.toString()}
                  key={index}
                >
                  {products.map((product, index) => (
                    <AccordionProductListItem
                      product={product}
                      active={products_active.includes(product.name)}
                      disabled={isProductDisabled(product)}
                      selectionCallback={onProductClick}
                      key={index}
                      willExceedMaxSelection={willExceedMaxSelection(product)}
                    />
                  ))}
                </BaseProductCategoryAccordion>
              )
          )}
          {groupedProducts[CONFLICT_CATEGORY] &&
            groupedProducts[CONFLICT_CATEGORY].length && (
              <div className="override-height-wrapper">
                <BaseProductCategoryAccordion
                  categoryIcon={indicatorIcons.Conflict}
                  categoryLabel={categoryLabelByCategory[CONFLICT_CATEGORY]}
                  selectionsCount={getCategoryCount(
                    groupedProducts[CONFLICT_CATEGORY],
                    products_active
                  )}
                  eventKey={Object.entries(groupedProducts).length.toString()}
                  key={Object.entries(groupedProducts).length}
                >
                  <AccordionConflictItem
                    conflictProducts={groupedProducts[CONFLICT_CATEGORY]}
                    isProductDisabled={isProductDisabled}
                  />
                </BaseProductCategoryAccordion>
              </div>
            )}
        </Accordion>
      )}
    </div>
  );
};

export default IndicatorsAccordionGroup;
