import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Loadable from "react-loadable";
import { navigate } from "gatsby";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog as faUserCogReg } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "react-bootstrap";
import { useI18next } from "gatsby-plugin-react-i18next";

import { isDomAvailable } from "../lib/util";
import Layout from "../components/Layout";
import Map from "../components/Map";
import Sidebar from "../components/Sidebar";
import NavLink from "../components/NavLink";
import UserAffirmationModal from "../components/UserAffirmationModal";
import { regionRequest, graphShowToggle } from "actions";
import Joyride from "react-joyride";
import TourModal from "../components/TourModal";
import { steps } from "../components/TourModal/util";
import "@fortawesome/fontawesome-free/css/all.min.css";

const LoadableGraphs = Loadable({
  loader: () => import("../components/Chart"),
  loading() {
    return <div>Loading...</div>;
  },
});

const IndexPage = (props) => {
  const {
    dispatch,
    region_data: { unique_region_text },
  } = props;
  const { language } = useI18next();
  // Needed for plotly to hydrate correctly
  // https://github.com/vercel/next.js/issues/7322#issuecomment-987086391
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, [dispatch]);

  useEffect(() => {
    dispatch(regionRequest(language));
  }, [hasMounted, dispatch, language]);

  if (!props.account_is_logged_in && isDomAvailable()) {
    navigate("/account/login");
    return <>Redirecting...</>;
  }

  if (!hasMounted) return null;

  const canShowGraph =
    props.products_active?.length &&
    props.features_active[props.layer_active?.name]?.length;

  const onClickToggleChart = () => {
    dispatch(graphShowToggle(true));
  };

  return (
    <Layout
      pageName="home"
      secondaryNav={
        <NavLink
          linkPath="/account/"
          linkText="Account"
          linkIcon={<FontAwesomeIcon icon={faUserCogReg} size="lg" />}
        />
      }
      isMap={true}
      showHeader={false}
    >
      <Helmet>
        <title>{unique_region_text?.siteTitle ?? ""}</title>
      </Helmet>
      <UserAffirmationModal
        affirm={props.user_disclaimer_affirmation}
        dispatch={props.dispatch}
      />
      {props.user_disclaimer_affirmation && !props.user_tutorial_completion && (
        <Joyride
          steps={steps}
          continuous={true}
          showProgress={true}
          tooltipComponent={TourModal}
          disableOverlay={true}
          disableScrolling={true}
        />
      )}
      <div className="map-container">
        <Map {...props}></Map>
        <span className="step-three" />
      </div>
      <Sidebar {...props} />
      {!canShowGraph ? null : props.graph_show ? (
        <LoadableGraphs {...props} />
      ) : (
        <div className="chart-show-container">
          <Button variant="primary" size="sm" onClick={onClickToggleChart}>
            Show chart
          </Button>
        </div>
      )}
    </Layout>
  );
};

export default connect((state) => state)(IndexPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
