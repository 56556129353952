import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, OverlayTrigger, Dropdown, Badge } from "react-bootstrap";
import { faAngleUp, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { Trans } from "gatsby-plugin-react-i18next";

import LayerDropdownToggle from "../LayerDropdownToggle";
import formatDate from "./helpers/formatDate";
import ProductInfoPopUp from "./ProductInfoPopup";
import { productsToggle } from "../../actions";

const AccordionConflictItem = ({ conflictProducts, isProductDisabled }) => {
  const { products_active: productsActive } = useSelector((state) => state);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const dispatch = useDispatch();

  const activeConflictProduct = useMemo(
    () =>
      conflictProducts.find((product) => productsActive.includes(product.name)),
    [productsActive, conflictProducts]
  );

  // The conflict eye button click should be disabled
  // when the selected date is out of range of all
  // conflict products
  const isConflictDisabled = conflictProducts.every((product) =>
    isProductDisabled(product)
  );

  // When there is an active conflict product:
  // - if the clicked product is the same, no-op and return,
  // - otherwise, toggle off the active conflict product;
  // Toggle on the clicked product
  const onClickProduct = (product) => {
    if (activeConflictProduct && activeConflictProduct.name === product.name)
      return;
    if (activeConflictProduct) {
      dispatch(productsToggle(activeConflictProduct.name));
    }
    dispatch(productsToggle(product.name));
  };

  // When there is an active conflict product, toggle it off;
  // Otherwise, toggle on the first available product based
  // on selected date
  const onClickIcon = () => {
    if (!!activeConflictProduct) {
      dispatch(productsToggle(activeConflictProduct.name));
    }
    if (!activeConflictProduct && conflictProducts.length) {
      const firstAvailableProduct = conflictProducts.find(
        (product) => !isProductDisabled(product)
      );
      if (!!firstAvailableProduct) {
        dispatch(productsToggle(firstAvailableProduct.name));
      }
    }
    if (dropDownOpen) {
      setDropDownOpen(false);
    }
  };

  return (
    <>
      <div
        className={`list-group-item conflict ${
          isConflictDisabled ? "disabled" : ""
        }`}
      >
        <div className="card-text-content">
          <div>
            <h4 className="label" aria-describedby="info-tooltip">
              <Trans>Conflict</Trans>
            </h4>
          </div>
          <small className="date-range">Multiple dates</small>
          {isConflictDisabled && (
            <Badge className="unavailable-badge">Unavailable</Badge>
          )}
        </div>
        <Button
          className={`card-selection-button${
            !!activeConflictProduct ? " active" : ""
          } ${isConflictDisabled ? "disabled" : ""}`}
          onClick={onClickIcon}
          disabled={isConflictDisabled}
        >
          <FontAwesomeIcon
            icon={!!activeConflictProduct ? faEye : faEyeSlash}
          />
        </Button>
      </div>
      {!!activeConflictProduct && (
        <div className="conflict-container">
          <Dropdown
            className="conflict-dropdown"
            onToggle={(nextShow) => setDropDownOpen(nextShow)}
          >
            <Dropdown.Toggle as={LayerDropdownToggle}>
              {activeConflictProduct && (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="right"
                  overlay={ProductInfoPopUp(activeConflictProduct)}
                >
                  {({ ref, ...triggerHandler }) => (
                    <div className="toggle-content" ref={ref}>
                      <div className="conflict-dropdown-item">
                        <div className="content">
                          <div className="label">
                            {activeConflictProduct.label}
                            {activeConflictProduct.description && (
                              <span {...triggerHandler}>
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  role="tooltip"
                                  id="info-tooltip"
                                />
                              </span>
                            )}
                          </div>
                          <div className="date-range">
                            {activeConflictProduct.date_min && (
                              <small>
                                {formatDate(activeConflictProduct.date_min)} -{" "}
                                {formatDate(activeConflictProduct.date_max)}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <FontAwesomeIcon
                        icon={dropDownOpen ? faAngleUp : faAngleDown}
                      />
                    </div>
                  )}
                </OverlayTrigger>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="conflict-dropdown-menu">
              {conflictProducts.map((product) => (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="right"
                  overlay={ProductInfoPopUp(product)}
                  key={product.name}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Dropdown.Item
                      href=""
                      ref={ref}
                      onClick={() => onClickProduct(product)}
                      key={product.name}
                      disabled={isProductDisabled(product)}
                    >
                      <div className="conflict-dropdown-item">
                        <div className="content">
                          <div className="label">
                            {product.label}
                            {product.description && (
                              <span {...triggerHandler}>
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  role="tooltip"
                                  id="info-tooltip"
                                />
                              </span>
                            )}
                          </div>
                          <div className="date-range">
                            {product.date_min && (
                              <small>
                                {formatDate(product.date_min)} -{" "}
                                {formatDate(product.date_max)}
                              </small>
                            )}
                            {isProductDisabled(product) && (
                              <Badge className="unavailable-badge">
                                Unavailable
                              </Badge>
                            )}
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                  )}
                </OverlayTrigger>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <div className="conflict-text-container">
            <p>
              <Trans i18nKey="conflictDisclaimer">
                Conflict data should be used cautiously and with due regard to
                its limitations. For further information on collection
                methodology or any other technical issues, please refer to the
                provider’s webpage.
              </Trans>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AccordionConflictItem;
