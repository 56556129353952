import React, { useState, useEffect, useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import {
  setActiveLayer,
  setActiveLayerGroup,
  timeseriesFeatureUpdate,
  getLayers,
} from "actions";

import LayerDropdownToggle from "./LayerDropdownToggle";

const organizeLayersForListDisplay = (list) =>
  list.reduce((listByGroups, layer) => {
    layer.grouping?.forEach((groupLabel) => {
      const groupLayers =
        groupLabel in listByGroups
          ? [...listByGroups[groupLabel], layer]
          : [layer];
      listByGroups[groupLabel] = groupLayers;
    });
    return listByGroups;
  }, {});

const LayerSelector = (props) => {
  const {
    dispatch,
    layers: { data: layersList, error: layersError },
    layer_active,
    layer_group_active,
  } = props;
  // Needed for react-bootstrap to hydrate correctly
  // https://github.com/vercel/next.js/issues/7322#issuecomment-987086391
  const [hasMounted, setHasMounted] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const { t } = useTranslation();
  const { language } = useI18next();

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    !layersList.length && !layersError && dispatch(getLayers());
  }, [dispatch, layersList, layersError]);

  useEffect(() => {
    dispatch(getLayers(language));
  }, [dispatch, language]);

  const groupedLayers = useMemo(
    () => organizeLayersForListDisplay(layersList),
    [layersList]
  );

  if (!hasMounted) return null;

  const onLayerClick = (layer, groupLabel) => {
    dispatch(setActiveLayer(layer));
    dispatch(setActiveLayerGroup(groupLabel));
    dispatch(timeseriesFeatureUpdate());
  };

  return Object.keys(groupedLayers).length ? (
    <div className="layer-selector">
      <div id="layer-dropdown-label">{t("Show boundaries")}</div>
      <Dropdown
        aria-labelledby="layer-dropdown-label"
        className="dropdown-custom-toggle"
        onToggle={(nextShow) => setDropDownOpen(nextShow)}
      >
        <Dropdown.Toggle as={LayerDropdownToggle}>
          <span className="toggle-content">
            <span className="toggle-title-content">
              {layer_group_active && <span>{layer_group_active}</span>}
              {layer_active?.description}
            </span>
            <FontAwesomeIcon icon={dropDownOpen ? faAngleUp : faAngleDown} />
          </span>
        </Dropdown.Toggle>
        <DropdownMenu>
          {Object.entries(groupedLayers).map(([groupLabel, layers], index) => (
            <span key={index}>
              <Dropdown.ItemText>{groupLabel}</Dropdown.ItemText>
              {layers.map((layer) => (
                <Dropdown.Item
                  as="button"
                  key={layer.id}
                  eventKey={layer.id}
                  onClick={() => onLayerClick(layer, groupLabel)}
                  active={layer_active?.name === layer.name}
                >
                  {layer.description}
                </Dropdown.Item>
              ))}
            </span>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  ) : null;
};

export default LayerSelector;
