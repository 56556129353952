import React, { useEffect, useMemo } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import ar from "date-fns/locale/ar";
import en from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import { dateSet } from "actions";

const getLangCodeImport = (langString) => {
  switch (langString) {
    case "fr":
      return fr;
    case "ar":
      return ar;
    default:
      return en;
  }
};

const SidebarDatePicker = () => {
  const {
    date,
    date_min: minDate,
    date_max: maxDate,
    products_active: activeProducts,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useI18next();

  const currentDate = useMemo(() => new Date(date), [date]);

  useEffect(() => {
    registerLocale(`${language}`, getLangCodeImport(language));
  }, [language]);

  const onClickChangeMonth = (isIncrement) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + (isIncrement ? 1 : -1));
    dispatch(dateSet(newDate));
  };

  const showForwardIcon = useMemo(() => {
    const max = new Date(maxDate);
    const futureMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1
    );
    return futureMonth <= new Date(max.getFullYear(), max.getMonth());
  }, [currentDate, maxDate]);

  const showBackwardIcon = useMemo(() => {
    const min = new Date(minDate);
    const previousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1
    );
    return previousMonth >= new Date(min.getFullYear(), min.getMonth());
  }, [currentDate, minDate]);

  return (
    <div className="sidebar-date-picker">
      <div className="sidebar-upper-label">{t("Select month")}</div>
      <div className="date-range-selection">
        <Button
          variant="light"
          className="date-picker-button date-picker-button-left"
          onClick={() => onClickChangeMonth(false)}
          disabled={activeProducts.length ? !showBackwardIcon : false}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <DatePicker
          selected={currentDate}
          onChange={(newDate) => dispatch(dateSet(newDate))}
          dateFormat="MMM yyyy"
          showMonthYearPicker
          showYearDropdown
          {...(activeProducts.length && {
            minDate: new Date(minDate),
            maxDate: new Date(maxDate),
          })}
          locale={`${language}`}
          popperPlacement="bottom-start"
        />
        <Button
          variant="light"
          className="date-picker-button date-picker-button-right"
          onClick={() => onClickChangeMonth(true)}
          disabled={activeProducts.length ? !showForwardIcon : false}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </div>
    </div>
  );
};

export default SidebarDatePicker;
