import mapboxgl from "mapbox-gl";
import buildPopupHTMLContent from "./buildPopupHTMLContent";

const MAPBOX_POPUP_CLASS_NAME = "mapboxgl-popup";
const INDIVIDUAL_CONFLICT_POPUP_CLASS_NAME = "persisting-conflict-popup";

export const removeOldPopups = (click) => {
  const oldPopups = document.getElementsByClassName(MAPBOX_POPUP_CLASS_NAME);
  if (oldPopups.length) {
    for (let i = oldPopups.length - 1; i >= 0; i--) {
      const popup = oldPopups[i];
      if (
        click ||
        !popup.className.includes(INDIVIDUAL_CONFLICT_POPUP_CLASS_NAME)
      ) {
        popup.remove();
      }
    }
  }
};

const addMapListeners = (
  global,
  map,
  activeLayerName,
  activeFeatures,
  hasVisibleProduct,
  translateFunc,
  onFeatureClick,
  activeConflictData
) => {
  if (global.mapOnMousemove) {
    map.off("mousemove", global.mapOnMousemove);
    global.mapOnMousemove = null;
  }

  let popup = new mapboxgl.Popup({
    closeButton: false,
    anchor: "bottom",
    offset: 5,
  });

  const getFeatures = (e) =>
    map.queryRenderedFeatures(e.point, {
      layers: map.getLayer("conflict-layer")
        ? [activeLayerName + "-fill", "conflict-layer"]
        : [activeLayerName + "-fill"],
    });

  const getFeatureFills = (features) =>
    features.filter((f) => f.layer.id === activeLayerName + "-fill");

  global.mapOnMousemove = function (e) {
    try {
      var features = getFeatures(e);
    } catch (error) {
      return;
    }

    if (features.length > 0) {
      const fills = getFeatureFills(features);
      const conflicts = features.filter((f) => f.layer.id === "conflict-layer");
      const feature = fills[0];
      const activeLayerFeatures = activeFeatures[activeLayerName] ?? [];
      map.getCanvas().style.cursor = "pointer";
      if (feature) {
        [...activeLayerFeatures, global.feature_id_hovering].forEach(
          (other_id) => {
            map.setFeatureState(
              {
                source: "features",
                sourceLayer: activeLayerName,
                id: other_id,
              },
              { hover: false }
            );
            map.setFeatureState(
              {
                source: "features",
                sourceLayer: activeLayerName + "-labels",
                id: other_id,
              },
              { hover: false }
            );
          }
        );
        if (feature.id) {
          map.setFeatureState(
            {
              source: "features",
              sourceLayer: activeLayerName,
              id: feature.id,
            },
            { hover: true }
          );
          map.setFeatureState(
            {
              source: "features",
              sourceLayer: activeLayerName + "-labels",
              id: feature.id,
            },
            { hover: true }
          );
          global.feature_id_hovering = feature.id;
        }
        if (feature.properties) {
          const content = buildPopupHTMLContent(
            feature,
            conflicts,
            translateFunc,
            activeConflictData
          );
          let ll = e.lngLat;
          if (hasVisibleProduct) {
            removeOldPopups();
            // Don't show individual conflict on hover, only click
            if (
              !conflicts.length ||
              (conflicts.length && conflicts[0].properties.cluster)
            ) {
              popup.setLngLat(ll).setHTML(content).addTo(map);
            }
          }
        }
      }
    } else {
      map.getCanvas().style.cursor = "";
      if (global.feature_id) {
        map.setFeatureState(
          {
            source: "features",
            sourceLayer: activeLayerName,
            id: global.feature_id,
          },
          { hover: false }
        );
        global.feature_id = null;
      }
      popup.remove();
    }
  };
  map.on("mousemove", global.mapOnMousemove);

  // Unregister any click listeners
  // This used to be done more idiomatically with map.off(...),
  // storing function references as a global, but those stopped
  // matching correctly, so we're just hacking into the map now.
  map._listeners.click = [];

  // Open graphs when clicking region of map
  const mapOnClick = function (e) {
    if (e.features.length > 0) {
      const fills = getFeatureFills(e.features);
      const feature = fills[0];

      onFeatureClick(feature);
    }
  };
  map.on("click", activeLayerName + "-fill", mapOnClick);

  map.on("mouseout", () => {
    removeOldPopups();
  });

  // Open/Close individual conflict popup on click
  map.on("click", (e) => {
    const activeConflictPopup = document.getElementsByClassName(
      INDIVIDUAL_CONFLICT_POPUP_CLASS_NAME
    );
    if (activeConflictPopup.length) {
      activeConflictPopup[0].remove();
    }
    const features = getFeatures(e);
    const conflicts = features.filter((f) => f.layer.id === "conflict-layer");
    const fills = getFeatureFills(features);
    const feature = fills[0];

    if (
      hasVisibleProduct &&
      feature?.properties &&
      conflicts.length &&
      !conflicts[0].properties.cluster
    ) {
      const content = buildPopupHTMLContent(
        feature,
        conflicts,
        translateFunc,
        activeConflictData
      );
      let ll = e.lngLat;
      popup.setLngLat(ll).setHTML(content).addTo(map);
      popup.addClassName(INDIVIDUAL_CONFLICT_POPUP_CLASS_NAME);
    }
  });
};

export default addMapListeners;
